import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, Container, Form } from 'react-bootstrap'
import CookieService from '../services/CookieService';
import APIService from '../services/APIService';
import { isAdmin } from "../services/UtilityService";

export default class Navigation extends Component {
    constructor(props) {
        super(props);
        const url = window.location.href
        this.state = {
            events: [],
            selectedEvent: localStorage.getItem('event') || '',
            loggedIn: false,
            currentPage: url.substring(url.lastIndexOf("/") + 1),
            adminLogin: false
        };
        this.handleEventChange = this.handleEventChange.bind(this);
    }

    componentDidMount() {
        if(CookieService.exists('token')){
            this.setState({adminLogin: isAdmin()});
            if(localStorage.getItem('event') === null){
                this.getEvents(true);
            } else {
                this.getEvents(false)
            }
            this.setState({adminLogin: localStorage.getItem('adminLogin')})
        }
    }

    logout() {
        let instanceId = localStorage.getItem('instanceId')
        let address = `/${instanceId}/destroy/session`
        APIService.delete("AUTH", address).then(response => {
            CookieService.remove('token');
            localStorage.removeItem('instanceId');
            localStorage.removeItem('event');
            localStorage.removeItem('user');
            window.location = '/';
        }).catch(error => {
            APIService.error(error, true)
        });
    }

    getEvents(setDefault) {
        let instanceId = localStorage.getItem('instanceId');
        var address = `${instanceId}/event`
        let currentComponent = this;
        APIService.get('CONFIG', address)
            .then(response => {
                let events = response.data["events"];
                currentComponent.setState({
                    events: events,
                    loggedIn: true
                })
                let currentEvent = localStorage.getItem('event')
                if(setDefault) {
                    currentComponent.setState({selectedEvent: events[events.length-1]})
                    localStorage.setItem('event', events[events.length-1]);
                } else {
                    currentComponent.setState({selectedEvent: currentEvent})
                }
                localStorage.setItem('eventsReady', true);
                if(currentEvent !== events[events.length-1] && ! localStorage.getItem("dontReload")) {
                    localStorage.setItem("dontReload", true)
                    window.location.reload(false);
                }
            }).catch(function(error) {
                console.log(error)
                APIService.error(error);
            });
    }

    updateEventSelect() {
        let events = this.state.events;
        let eventOptions = events.map(event => {
            return <option value={event} key={event._id}>{event}</option>
        })
        return eventOptions
    }

    handleEventChange(event) {
        localStorage.setItem('event', event.target.value);
        window.location.reload(false);
    }

    styleNavbarCurrentPage(page) {
        if (this.state.currentPage === page) {
            return ({color: "#fff"})
        } else {
            return ({color: "#ccc"})
        }
    }

    render() {
        return (
            <Navbar collapseOnSelect expand="lg" className="bg-theory" variant="dark">
                <Container>
                    <Navbar.Brand href="/">
                        <img
                        alt=""
                        src="/brand.png"
                        width="99.7"
                        height="112"
                        className="d-inline-block align-top"
                        />{' '}
                    1241 Scouting
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                    {
                        JSON.parse(localStorage.getItem('adminMode')) === true
                    ?
                        <Nav className="me-auto">
                            <Nav.Link id="matchedit" style={this.styleNavbarCurrentPage("matchedit")} href="/matchedit">Match List</Nav.Link>
                            <Nav.Link id="normal" href="/">User Mode</Nav.Link>
                        </Nav>
                    :
                        <Nav className="me-auto">
                            <Nav.Link id="input" style={this.styleNavbarCurrentPage("input")} href="/input">Input</Nav.Link>
                            <Nav.Link id="rawdata" style={this.styleNavbarCurrentPage("rawdata")} href="/rawdata">Raw Data</Nav.Link>
                            <Nav.Link id="metrics" style={this.styleNavbarCurrentPage("metrics")} href="/metrics">Metric Viewer</Nav.Link>
                            <Nav.Link id="metricscompare" style={this.styleNavbarCurrentPage("metricscompare")} href="/metricscompare">Team Compare</Nav.Link>
                            {
                                JSON.parse(this.state.adminLogin) ?

                                <Nav.Link id="admin" href="/matchedit">Admin Mode</Nav.Link> : null
                            }
                        </Nav>
                    }
                    <Nav className="mr-auto">
                            {
                            CookieService.exists('token')
                            ?
                            <Navbar.Text onClick={this.logout}>
                                Signed in as: {localStorage.getItem('user')}
                            </Navbar.Text>
                            :
                            <Nav.Link href="/register" style={this.styleNavbarCurrentPage("login")}> Register</Nav.Link>
                            }
                            </Nav>
                            <Nav className="px-3">
                            {
                            CookieService.exists('token')
                            ?
                            <Navbar.Text>
                                <Form.Select name="events" id="events" value={this.state.selectedEvent} onChange={this.handleEventChange}>,
                                    {
                                        this.state.loggedIn ?
                                        this.updateEventSelect()
                                        :
                                        <option value="NONE">Event...</option>
                                    }
                                </Form.Select>
                            </Navbar.Text>
                            :
                            null
                            }
                    </Nav>
                </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}