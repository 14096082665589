import React, { Component } from 'react';
// import { BrowserRouter as Link, Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import MetricsDataPage from './MetricsDataPage';
import { Container, Row, Col } from 'react-bootstrap'

export default class MetricsDataComparePage extends Component {
    constructor(props) {
        super(props);
        this.labels = []
        this.metricData = {}
        this.matches = []
        this.state = {
            rawData: [],
            teamNumber: "",
            ready: false
        }
    }

    componentDidMount() {
        let instanceID = localStorage.getItem("instanceId");
        let event = localStorage.getItem("event");

        if (instanceID === null || event === null) {
            window.location = '/'
        }
    }

    render() {
        return(
            <Container fluid>
                <Row>
                    <Col><MetricsDataPage title={false}/></Col>
                    <Col><MetricsDataPage title={false}/></Col>
                    <Col><MetricsDataPage title={false}/></Col>
                </Row>
                <Row>
                    <Col><MetricsDataPage title={false}/></Col>
                    <Col><MetricsDataPage title={false}/></Col>
                    <Col><MetricsDataPage title={false}/></Col>
                </Row>
            </Container>
        )
    }

}