import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import LoginPage from './components/LoginPage';
import RegistrationPage from './components/RegisterPage';
import InputPage from './components/InputterPage';
import RawPage from './components/RawDataPage';
import MetricPage from './components/MetricsDataPage';
import MetricsDataComparePage from './components/MetricsDataComparePage';
import MatchListPage from './components/MatchListPage';
import CookieService from './services/CookieService';

class App extends Component {

    constructor(props) {
        super(props);
        this.CURRENT_VERSION = '1.1.1';
    }


    componentDidMount() {
        if (localStorage.getItem("VERSION") !== this.CURRENT_VERSION || ! CookieService.exists('token')) {
            localStorage.clear();
            CookieService.remove('token');
            localStorage.setItem("VERSION", this.CURRENT_VERSION);
        }
        localStorage.setItem('eventsReady', false);
    }


    render() {
        return (
            <Router>
                <Navbar fixed="top"/>
                <div className="container">
                    <Route path="/" exact component={InputPage}/>
                    <Route path="/login" component={LoginPage}/>
                    <Route path="/input" component={InputPage}/>
                    <Route path="/rawdata" component={RawPage}/>
                    <Route path="/metrics" component={MetricPage}/>
                    <Route path="/metricscompare" component={MetricsDataComparePage}/>
                    <Route path="/register" component={RegistrationPage}/>
                    <Route path="/matchedit" component={MatchListPage}/>
                </div>
                <Footer/>
            </Router>
      );
    }
}

export default (App);