import React, { Component } from 'react';
// import { BrowserRouter as Link, Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Container, Row, Col, FloatingLabel, Button, Form, Accordion } from 'react-bootstrap';
import { objectValueSort, pageReady } from '../services/UtilityService'
import APIService from '../services/APIService';

export default class MetricsDataPage extends Component {
    constructor(props) {
        super(props);
        this.labels = []
        this.metricData = {}
        this.matches = []
        this.state = {
            rawdata: [],
            teamNumber: "",
            ready: false,
            title: true
        }
        this.handleUpdateTeamNumber = this.handleUpdateTeamNumber.bind(this);
        if (props.title !== undefined ) { this.state.title = props.title }
    }

    componentDidMount() {
        pageReady();
        let instanceID = localStorage.getItem("instanceId");
        let event = localStorage.getItem("event");

        if (instanceID === null || event === null) {
            window.location = '/'
        }
        this.getConfigData();
    }

    populateTable(){
        var metricData = this.metricData;
        if(Object.keys(metricData).length !== 0) {
            var returnTag = [];
            var labels = this.labels
            for (let idx = 0; idx < labels.length; idx++) {
                var fields = Object.keys(metricData[labels[idx]]);
                var fieldArray = [];
                fields.forEach(field => {
                    fieldArray.push([field, metricData[labels[idx]][field]])
                })
                returnTag.push(
                    fieldArray !== [] ?
                    <Accordion.Item eventKey={idx}>
                        <Accordion.Header>{labels[idx]}</Accordion.Header>
                        <Accordion.Body>
                            <Table responsive hover striped key={idx}>
                                <tbody>
                                    {fieldArray.map((values) => (
                                        <tr>
                                            <th>{String(values[0])}</th>
                                            <td>{values[1] == null ? 'N/A' : String(values[1].toFixed(2))}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                : null
                )
            }
            return (returnTag);
        } else {
        return <h3>Data loading {'.'.repeat(Math.floor((Math.random() * 5) + 1))}</h3>;
        }
    }

    getConfigData() {
        let instanceId = localStorage.getItem("instanceId")
        let event = localStorage.getItem("event")
        let metricFieldsAddress = `${instanceId}/${event}/metrics`
        let labelsAddress = `${instanceId}/${event}/labels`

        APIService.get('CONFIG', labelsAddress).then(labelsResponse => {
            let labels = Object.keys(objectValueSort(labelsResponse.data.labels));
            labels.push("uncategorized");
            this.labels = labels;
            let metricData = {"uncategorized": {}}
            labels.forEach(label => { metricData[label] = {} });
            APIService.get('CONFIG', metricFieldsAddress).then(fieldsResponse => {
                let metricFields = fieldsResponse.data.metrics;
                metricFields.forEach(field => {
                    if(field.label === undefined) { field.label = "uncategorized" }
                    metricData[field.label][field.name] = -1
                });
                this.metricData = metricData;
            }).catch(function(error) {
                APIService.error(error);
            });
        }).catch(function(error) {
            APIService.error(error);
        });
    }

    clearData() {
        var metricData = this.metricData;
        var labels = Object.keys(metricData);
        labels.forEach(label => {
            var fields = Object.keys(metricData[label]);
            fields.forEach(field => {
                metricData[label][field] = -1
            })
        });
        this.metricData = metricData;
    }

    getData() {
        this.clearData();
        var teamNumber = this.state.teamNumber;
        if (teamNumber !== "") {
            let instanceId = localStorage.getItem("instanceId");
            let event = localStorage.getItem("event")
            let ADDRESS = `${instanceId}/${teamNumber}/${event}/metric`
            let metricData = this.metricData;
            APIService.get("METRIC", ADDRESS).then(response => {
                let data = response.data.data;
                // {metric1: 1, metric2: 2, ...}
                // {label1: {metric1: -1, metric2: -1, metric3: -1} }
                let labels = Object.keys(metricData);
                labels.forEach(label => {
                    let metrics = Object.keys(metricData[label])
                    metrics.forEach(metric => {
                        console.log(data[0])
                        metricData[label][metric] = data[0]["metrics"][metric]
                        // metricData[label][metric] = Math.floor((Math.random() * 100) + 1);
                    })
                });
                this.metricData = metricData;
                console.log(metricData)
                this.setState({
                    ready: true
                })
            }).catch(function(error) {
                APIService.error(error);
            });
        }
    }

    handleUpdateTeamNumber(event) {
        this.setState({teamNumber: event.target.value});
    }

    render() {

        const handleSubmit = (event) => {
            event.preventDefault();
            this.getData()
        };

        return(
                <div className='container'>
                    { this.state.title ? <h1> Metrics </h1> : null }
                    <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3">
                                    <Container>
                                        <Row xs="auto">
                                            <Col>
                                            <FloatingLabel label="Team Number" className="mb-3">
                                                <Form.Control onChange={this.handleUpdateTeamNumber} id="teamNumber" type="number" placeholder="Team Number" />
                                            </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <Button type="submit" variant="primary" id="button-addon2"> Submit </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Form.Group>
                            </Form>
                    <Accordion alwaysOpen>
                        {this.state.ready ? this.populateTable() : null}
                    </Accordion>
                </div>        )
    }
}