import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
export default class Footer extends Component {

    render() {
        return (
            
            <footer id="sticky-footer" className="page-footer font-small blue pt-4">

    <div className="footer-copyright text-center py-3">© 2024 Copyright:
    Engineered by the team at Hop Over
    </div>

</footer>
        );
    }
}