import React, { Component } from 'react';
// import { BrowserRouter as Link, Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Form, Accordion, Row, Col } from 'react-bootstrap';
import { objectValueSort, pageReady } from "../services/UtilityService"
import APIService from '../services/APIService';
export default class InputterPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: [],
            labels: {},
            teamList: [],
            rawFormData: {},
            editValue: props.location.state || {}
        }
    }

    componentDidMount() {
        pageReady();
        localStorage.setItem('adminMode', false)
        let instanceId = localStorage.getItem('instanceId');
        let event = localStorage.getItem('event');
        let currentComponent = this;
        if(event != null) {
            APIService.get("CONFIG", `${instanceId}/${event}/raw_fields`)
            .then(response => {
                var rawFields = response.data.raw_fields;
                currentComponent.setState({fields: rawFields})
            }).catch(function(error) {
                APIService.error(error, true);
                currentComponent.setState({
                    isValid: false
                })
            });
            APIService.get("CONFIG", `${instanceId}/${event}/labels`)
            .then(response => {
                var labels = response.data.labels;
                currentComponent.setState({
                    labels: Object.keys(objectValueSort(labels))
                })
            }).catch(function(error) {
                APIService.error(error, true);
                currentComponent.setState({
                    isValid: false
                })
            });
        }
    }


    fieldsList() {
        var mapArray = []
        var categorizedArray = []
        var uncategorizedArray = []
        var labelsLength = this.state.labels.length;
        for(var i = 0; i < labelsLength; i++) {
            // Iterate through labels
            var currentLabel = this.state.labels[i]
            for(var j = 0; j < this.state.fields.length; j++) {
                // Iterate through fields
                var currentField = this.state.fields[j];
                if(currentField.label === currentLabel) {
                    categorizedArray.push(this.createInput(currentField))
                } else if (i === labelsLength - 1 && currentField.label === undefined) {
                    // For last iteration of loop, get all the non-labeled field
                    uncategorizedArray.push(this.createInput(currentField))
                }
                if (j === this.state.fields.length - 1) {
                    mapArray.push(
                        <Accordion.Item eventKey={i}>
                            <Accordion.Header>{currentLabel}</Accordion.Header>
                            <Accordion.Body>{categorizedArray}</Accordion.Body>
                        </Accordion.Item>)
                    categorizedArray = []
                }
            }
        }
        if (uncategorizedArray !== []) {
            mapArray.push(
                <Accordion.Item eventKey={labelsLength+1} key={labelsLength+1}>
                    <Accordion.Header>Uncategorized</Accordion.Header>
                    <Accordion.Body>
                        {uncategorizedArray}
                    </Accordion.Body>
                </Accordion.Item>
            );
        }
        mapArray.push(<Button variant="primary" type="submit">Submit</Button>);
        return(mapArray);
    }

    createInput(currentField) {
        let inputTag = ""
        let fieldType = currentField.input_type
        let dataType = currentField.data_type
        let name = currentField.name
        let editValue = this.state.editValue[name]
        if (fieldType === "radio") { fieldType = "toggle"; }
        // eslint-disable-next-line default-case
        switch (fieldType) {
            case "typed":
                if (dataType === "str") {
                    inputTag = <Form.Group className="mb-3">
                                    <Form.Label>{name}</Form.Label>
                                    <Form.Control id={name} aria-label={name} type="text" placeholder={name} defaultValue={editValue} />
                                </Form.Group>
                } else if (dataType === "int") {
                    inputTag = <Form.Group className="mb-3">
                                    <Row>
                                            <Form.Label>{name}</Form.Label>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Control id={name} aria-label={name} type="number" placeholder={name} defaultValue={editValue} />
                                        </Col>
                                        <Col xs={5} lg={2}>
                                            <Button className="me-1 disableZoom" onClick={() => this.incrementInput("+", name)}>+</Button>
                                            <Button className="me-1 disableZoom" onClick={() => this.incrementInput("-", name)}>-</Button>
                                        </Col>
                                    </Row>
                                </Form.Group>
                }
                break;
            case "toggle":
                var options = currentField.options
                inputTag = <div key={`inline-checkbox`} className="mb-3">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Row>
                                        <Form.Label><strong>{name}</strong></Form.Label>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {options.map((optionName) => (
                                                <Form.Check
                                                inline
                                                label={String(optionName)}
                                                name={name}
                                                defaultChecked={String(editValue)===String(optionName)}
                                                value={String(optionName)}
                                                type="radio"
                                                id={`${optionName}-radio`}
                                                />
                                                ))}
                                        </Col>
                                        <Col xs={5} lg={2}>
                                            <Button className="me-1 disableZoom" onClick={() => this.clearRadio(name)}>Clear</Button>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </div>
                break;
            case "selection":
                var options = currentField.options
                inputTag = <div key={`inline-checkbox`} className="mb-3">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label><strong>{name}</strong></Form.Label>
                                    <br />
                                    {options.map((optionName) => (
                                        <Form.Check
                                        inline
                                        label={String(optionName)}
                                        name={name}
                                        defaultChecked={String(editValue).includes(String(optionName))}
                                        value={String(optionName)}
                                        type="checkbox"
                                        id={`${optionName}-checkbox`}
                                        />
                                    ))}
                                </Form.Group>
                            </div>
                break;
        }

        return (
            <>
                {inputTag}
            </>
        )
    }

    incrementInput(operation, name) {
        let value = document.getElementById(name).value;
        value = value === "" ? 0 : value;
        if (operation === "+"){
            document.getElementById(name).value = (parseInt(value)+1);
        } else if (operation === "-"){
            document.getElementById(name).value = (parseInt(value)-1);
        }
    }

    clearRadio(name) {
        let elementArray = document.getElementsByName(name);
        elementArray.forEach(element => {
            element.checked = false;
        });
    }

    render() {
        const handleSubmit = (event) => {
            event.preventDefault();
            for(var fieldIdx = 0; fieldIdx < this.state.fields.length; fieldIdx++) {
                var currentField = this.state.fields[fieldIdx];
                let defaultValue = currentField.default == undefined ? null : currentField.default;
                if (currentField.input_type !== "selection") {
                    let modifableRawFormData = Object.assign({}, this.state.rawFormData);
                    let currentFieldValue = event.target.elements[currentField.name].value;
                    if (currentFieldValue === '') {
                            currentFieldValue = defaultValue;
                    } else if (currentField.data_type === "int") {
                            currentFieldValue = Number(currentFieldValue);
                    } else if (currentField.data_type === "bool") {
                            currentFieldValue = JSON.parse(currentFieldValue);
                    }
                    modifableRawFormData[currentField.name] = currentFieldValue;
                    this.state.rawFormData[currentField.name] = currentFieldValue;
                } else if (currentField.input_type === "selection") {
                    var checkboxList = []
                    event.target.elements[currentField.name].forEach(checkboxes => {
                        if(checkboxes.checked === true) {
                            checkboxList.push(checkboxes.value);
                        }
                    });
                    if (checkboxList.length == 0) {
                        checkboxList.push(defaultValue)
                    }
                    let modifableRawFormData = Object.assign({}, this.state.rawFormData);
                    modifableRawFormData[currentField.name] = checkboxList.toString();
                    this.state.rawFormData[currentField.name] = checkboxList.toString();
                }
            }
            let team = event.target.elements["Team Number"].value
            let match = event.target.elements["Match Number"].value
            let instanceId = localStorage.getItem('instanceId')
            let selectedEvent = localStorage.getItem('event')

            if (Object.keys(this.state.editValue).length !== 0) {
                APIService.delete("METRIC", `${instanceId}/${selectedEvent}/${team}/${match}/raw_data`)
                .then(response => {
                    postMatch(instanceId, team, selectedEvent, match);
                }).catch(function(error) {
                    APIService.error(error);
                });
            } else {
                postMatch(instanceId, team, selectedEvent, match);
            }
        };
        const postMatch = (instanceId, team, selectedEvent, match) => {
            APIService.post("METRIC", `${instanceId}/${team}/${selectedEvent}/${match}/raw_data`, this.state.rawFormData)
                .then(response => {
                    alert("Successfully Submitted")
                    window.location.reload(true);
                }).catch(function(error) {
                    APIService.error(error);
                });
            }
        return(
                <div className='container'>
                    <h1>Data Inputter</h1>
                    <Form onSubmit={handleSubmit}>
                        <Accordion alwaysOpen>
                            {this.fieldsList()}
                        </Accordion>
                    </Form>
                    <div id='formValidation'></div>
                </div>
        )
    }
}