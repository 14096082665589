import React, { Component } from "react";
import { Alert, Form, FloatingLabel } from 'react-bootstrap';
import CookieService from "../services/CookieService";
import APIService from "../services/APIService";
import { pageReady } from "../services/UtilityService";
var crypto = require('crypto');

export default class RegisterPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            instanceId:'',
            username: '',
            password: '',
            passwordConfirm: '',
            valid: true,
            token: CookieService.exists("token") ? CookieService.get("token") : ''
        };
        this.handleSetUsername = this.handleSetUsername.bind(this);
        this.handleSetPassword = this.handleSetPassword.bind(this);
        this.handleSetPasswordConfirm = this.handleSetPasswordConfirm.bind(this);
    }

    async componentDidMount(){
        pageReady(false);
    }

	submit = async (e) => {
		e.preventDefault();
		try {
            if (this.state.password !== this.state.passwordConfirm) {
                return(this.setState({valid: false}))
            }
            const admin = e.target.elements.admin.checked;
            let hash = crypto.createHash('sha256').update(this.state.password).digest('hex');
            const user = this.state.username;
            let instanceId = this.state.instanceId;
			// Make API call here
            let params = {'password': hash, 'admin': admin};
            let currentComponent = this;
            APIService.post('AUTH', `RHRScouting2024Test/${user}/account`, params)
                .then(response => {
                    alert("Registered!")
                    this.props.history.push({
                        pathname: '/'
                    })
                }).catch(function(error) {
                    currentComponent.setState({
                        isValid: false
                    })
                    APIService.error(error);
                });
		} catch (err) {
            console.log(err)

		}
	}

    handleSetInstanceId(event) {
        console.log(event.target.value)
        this.setState({instanceId: event.target.value});
    }

    handleSetUsername(event) {
        this.setState({username: event.target.value});
    }

    handleSetPassword(event) {
        this.setState({password: event.target.value});
    }

    handleSetPasswordConfirm(event) {
        this.setState({passwordConfirm: event.target.value});
    }


	render() {
        return (
                <div className="mt-5">
                    <h1> Sign up</h1>
                        <Form className="form" onSubmit={this.submit}>
                        <Form.Group className="mb-3">
                        <FloatingLabel
                                    controlId="instanceId"
                                    label="Enter an instance ID"
                                    className="mb-3"
                                >
                                    <Form.Control onChange={this.handleSetUsername} type="text" placeholder="Enter a instance ID" />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="username"
                                    label="Enter a new Username"
                                    className="mb-3"
                                >
                                    <Form.Control onChange={this.handleSetUsername} type="text" placeholder="Enter a new Username" />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="password"
                                    label="Create a Password"
                                    className="mb-3"
                                >
                                    <Form.Control onChange={this.handleSetPassword} type="password" placeholder="Create a Password" />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="passwordConfirm"
                                    label="Confirm your Password"
                                    className="mb-3"
                                >
                                    <Form.Control onChange={this.handleSetPasswordConfirm} type="password" placeholder="Confirm your Password" />
                                </FloatingLabel>
                            </Form.Group>
                            <div key='checkbox' className="mb-3">
                                <Form.Check
                                    type='checkbox'
                                    id='admin'
                                    label='Will this be an Admin User?'
                                />
                            </div>
                            <div className="form-group">
                                <input type="submit" value="Login" className="button button-primary" />
                            </div>
                            {this.state.valid ?
                                    null : (
                                    <Alert variant={'danger'}>
                                        Your passwords don't match
                                    </Alert>
                                    )
                            }
                        </Form>
                </div>
        );
    }

}