import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Accordion, Row, Col } from 'react-bootstrap';
import { pageReady } from "../services/UtilityService"
import APIService from '../services/APIService';
export default class MatchList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: [],
            rawData: {},
        }
    }

    componentDidMount() {
        pageReady();
        localStorage.setItem('adminMode', true);
        let instanceId = localStorage.getItem('instanceId');
        let event = localStorage.getItem('event');
        // let currentComponent = this;
        if(event != null) {
            let params = {event: event}
            APIService.get("METRIC", `${instanceId}/data`, params)
            .then(response => {
                let data = response.data.data; 
                data.reverse();
                this.setState({rawData: data});
            })
        }
    }

    jsonToList(jsonData) {
        let items = ""
        jsonData = JSON.parse(jsonData);
        for (var key in jsonData) {
            items += key + ": " + jsonData[key] + ", \n";
        }
        return(items)
    }

    editEvent(data) {
        console.log(data.raw_data)
        this.props.history.push({
            pathname: '/input',
            state: data.raw_data
          });
    }

    deleteEvent(data) {
        let confirmBox = window.confirm(
            `Are you sure you want to delete Match ${data.match} for Team ${data.team}?`
        )
        if (confirmBox === true) {
            let event = localStorage.getItem('event');
            let instanceId = localStorage.getItem('instanceId');

            APIService.delete("METRIC", `${instanceId}/${event}/${data.team}/${data.match}/raw_data`)
            .then(response => {
                alert(`Deleted match ${data.match} for Team ${data.team}!`)
                window.location.reload(true);
            }).catch(function(error) {
                APIService.error(error, true);
            });
        }
    }

    

    populateMatchList() {
        let accordianArray = []
        for (let i = 0; i < this.state.rawData.length; i ++) {
            let entry = this.state.rawData[i];
            let headerString = `Match: ${entry.match} - Team: ${entry.team}`;
            let bodyString = JSON.stringify(entry.raw_data);
            let bodyList = this.jsonToList(bodyString);

            accordianArray.push(
                <Row>
                    <Col xs={5} lg={2}>
                        <Button className="me-1" onClick={() => this.editEvent(entry)} variant="warning">Edit</Button>
                        <Button className="me-1" onClick={() => this.deleteEvent(entry)} variant="danger">Delete</Button>
                    </Col>
                    <Col>
                        <Accordion.Item eventKey={i}>
                            <Accordion.Header>{headerString} 
                            </Accordion.Header>
                            <Accordion.Body>{bodyList}</Accordion.Body>
                        </Accordion.Item>
                    </Col>
                </Row>
            )
        }
        return accordianArray;
    }


    render() {
        return(
                <div className='container'>
                    <h1>Match List</h1>
                    <Accordion>
                        {this.populateMatchList()}
                    </Accordion>
                </div>
        )
    }
}