/* eslint-disable no-loop-func */
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, FloatingLabel, Button, Form, Accordion, Container, Row, Col } from 'react-bootstrap';
import { objectValueSort, transpose, pageReady } from '../services/UtilityService'
import APIService from '../services/APIService';

export default class RawDataPage extends Component {
    constructor(props) {
        super(props);
        this.labels = []
        this.rawData = {}
        this.matches = []
        this.state = {
            rawData: [],
            teamNumber: "",
            ready: false
        }
        this.handleTeamChange = this.handleTeamChange.bind(this);

    }

    componentDidMount() {
        pageReady();
        let instanceID = localStorage.getItem("instanceId");
        let event = localStorage.getItem("event");

        if (instanceID === null || event === null) {
            window.location = '/'
        }
        this.getConfigData();
    }

    populateTable(){
        var rawData = this.rawData;
        if(Object.keys(rawData).length !== 0) {
            var returnTag = [];
            var labels = this.labels
            for (let idx = 0; idx < labels.length; idx++) {
                var fields = Object.keys(rawData[labels[idx]]);
                var fieldArrHeader = []
                var fieldArrBody = []
                fields.forEach(field => {
                    fieldArrHeader.push(field)
                    fieldArrBody.push(rawData[labels[idx]][field])
                })
                returnTag.push(
                    fieldArrHeader !== [] ?
                        <Accordion.Item eventKey={idx}>
                            <Accordion.Header>{labels[idx]}</Accordion.Header>
                            <Accordion.Body>
                                <Table responsive hover striped key={idx}>
                                    <thead>
                                        <tr>
                                        {fieldArrHeader.map((heading, index) => (
                                            <th key={index}>{heading}</th>
                                        ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transpose(fieldArrBody).map((values) => (
                                            <tr>
                                                {values.map((entries) => (
                                                    <td>{Array.isArray(entries) ?
                                                        entries.join(", ")
                                                        :
                                                        typeof(entries) == 'number' ? String(entries.toFixed(2)) : String(entries)}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                    : null
                )
            }
            return (returnTag);
        } else {
        return <h3>Data loading {'.'.repeat(Math.floor((Math.random() * 5) + 1))}</h3>;
        }
    }

    getConfigData() {
        let instanceId = localStorage.getItem("instanceId")
        let event = localStorage.getItem("event")
        let rawFieldsAddress = `${instanceId}/${event}/raw_fields`
        let labelsAddress = `${instanceId}/${event}/labels`
        let derivedFieldsAddress = `${instanceId}/${event}/derived_fields`

        APIService.get('CONFIG', labelsAddress).then(labelsResponse => {
            let labels = Object.keys(objectValueSort(labelsResponse.data.labels));
            labels.push("uncategorized");
            this.labels = labels;
            let rawData = {"uncategorized": {}}
            labels.forEach(label => { rawData[label] = {} });

            APIService.get('CONFIG', rawFieldsAddress).then(fieldsResponse => {
                let rawFields = fieldsResponse.data.raw_fields;
                APIService.get('CONFIG', derivedFieldsAddress).then(derivedFieldsResponse => {
                    let allFields = rawFields.concat(derivedFieldsResponse.data.derived_fields)
                    allFields.forEach(field => {
                        if(field.label === undefined) { field.label = "uncategorized" }
                        rawData[field.label][field.name] = []
                    });
                    this.rawData = rawData;
                    console.log(rawData)

                })
            }).catch(function(error) {
                console.log("Config Error")
                APIService.error(error);
            });
        }).catch(function(error) {
            console.log("Labels Error")
            APIService.error(error);
        });
    }

    clearData() {
        var rawData = this.rawData;
        var labels = Object.keys(rawData);
        labels.forEach(label => {
            var fields = Object.keys(rawData[label]);
            fields.forEach(field => {
                rawData[label][field] = []
            })
        });
        this.rawData = rawData;
    }

    getData() {
        this.clearData();
        var teamNumber = this.state.teamNumber;
        if (teamNumber !== "") {
            let instanceId = localStorage.getItem("instanceId");
            let event = localStorage.getItem("event")
            let ADDRESS = `${instanceId}/data`
            let parameters = {
                team: teamNumber,
                event: event
            }
            APIService.get("METRIC", ADDRESS, parameters).then(response => {
                console.log(response)
                let data = response.data.data;
                let rawData = this.rawData;
                let labels = Object.keys(rawData);
                for (let i = 0; i < data.length; i++) {
                    this.matches.push(data[i].match)
                    labels.forEach(label => {
                        var fields = Object.keys(rawData[label]);
                        fields.forEach(field => {
                            var entry = data[i].raw_data[field] === undefined ? "_" : data[i].raw_data[field]
                            rawData[label][field].push(entry);
                        })
                    });
                }
                this.setState({
                    ready: true
                })
            }).catch(function(error) {
                console.trace();
                APIService.error(error);
            });
        }
    }

    handleTeamChange(event) {
        this.setState({teamNumber: event.target.value});
    }



    render() {

        const handleSubmit = (event) => {
            event.preventDefault();
            this.getData()
        };

        return(
                <div className='container'>
                    <h1> Raw Data </h1>
                    <Form>
                                <Form.Group className="mb-3">
                                    <Container>
                                        <Row xs="auto">
                                            <Col>
                                            <FloatingLabel label="Enter your Team Number" className="mb-3">
                                                <Form.Control onChange={this.handleTeamChange} id="teamNumber" type="number" placeholder="Enter your Team Number" />
                                            </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <Button onClick={handleSubmit} variant="primary" id="button-addon2"> Submit </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Form.Group>
                            </Form>
                    <Accordion alwaysOpen>
                        {this.state.ready ? this.populateTable() : null}
                    </Accordion>
                </div>
        )
    }
}