
import CookieService from "./CookieService"
import APIService from "./APIService"

export const objectValueSort = (obj) => {
    const ret = {}
    Object.values(obj)
        .sort()
        .forEach(val => {
            const key = Object.keys(obj).find(key => obj[key] === val)
            ret[key] = val
        })
    return ret
}

export function nonAuthenticationPages() {
    const pages = ['/login', '/register']
    return pages
}

export const pageReady = (requireEvent=true, requireAuthentication=true) => {
    let nonAuthPages = ['/login', '/register']
    if(requireAuthentication){
        let instanceID = localStorage.getItem("instanceId");
        let token = CookieService.exists("token") ? CookieService.get("token") : ''
        if((instanceID == null || token === '') && nonAuthenticationPages().indexOf(window.location.pathname) == -1) {
                window.location = "/login"
            } 
    }
}

export const isAdmin = () => {
    let instanceID = localStorage.getItem("instanceId");
    APIService.get("AUTH", `${instanceID}/verify/admin`, {'no-slash': 'no-slash'})
    .then(response => {
        if(response.data.exists) {
            localStorage.setItem('adminLogin', true);
            return true;
        } else {
            localStorage.setItem('adminLogin', false);
            return false;
        }
    }).catch(function(error) {
        APIService.error(error, true);
        return false;
    });
}

export const transpose = (a) => {
    if (a.length === 0) { return a }
    return a[0].map(function (_, c) { return a.map(function (r) { return r[c]; }); });
}