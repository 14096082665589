import CookieService from "./CookieService";
import axios from "axios";

class APIService {

    constructor() {
        this.CONFIG_ENDPOINT = 'https://d1yromouqfny6v.cloudfront.net';
        this.AUTH_ENDPOINT = 'https://d32gqr8y48jknz.cloudfront.net';
        this.METRIC_ENDPOINT = 'https://du53jqcngwnn7.cloudfront.net';
        this.HEADERS = { 'Access-Control-Allow-Origin': '*', 'Cache-Control': 'no-cache' };
        if (CookieService.exists('token')) { this.HEADERS['Authorization'] = CookieService.get('token'); }
    }

    get (mode, address, parameters=null) {
        let ENDPOINT = ''
        if (mode === 'CONFIG') {
            ENDPOINT = this.CONFIG_ENDPOINT;
        } else if (mode === 'AUTH') {
            ENDPOINT = this.AUTH_ENDPOINT
        } else if (mode === 'METRIC') {
            ENDPOINT = this.METRIC_ENDPOINT
        }
        if (parameters) {
            return(axios.get(`${ENDPOINT}/${address}`, { headers: this.HEADERS, params: parameters }))
        } else {
            return(axios.get(`${ENDPOINT}/${address}/`, { headers: this.HEADERS }))
        }
    }

    post (mode, address, data) {
        let ENDPOINT = ''
        if (mode === 'CONFIG') {
            ENDPOINT = this.CONFIG_ENDPOINT;
        } else if (mode === 'AUTH') {
            ENDPOINT = this.AUTH_ENDPOINT
        } else if (mode === 'METRIC') {
            ENDPOINT = this.METRIC_ENDPOINT
        }

        return(axios.post(`${ENDPOINT}/${address}`, {...data} , {headers: this.HEADERS} ))
    }

    put (mode, address, data, parameters=null) {
        let ENDPOINT = ''
        if (mode === 'CONFIG') {
            ENDPOINT = this.CONFIG_ENDPOINT;
        } else if (mode === 'AUTH') {
            ENDPOINT = this.AUTH_ENDPOINT
        } else if (mode === 'METRIC') {
            ENDPOINT = this.METRIC_ENDPOINT
        }

        if (parameters) {
            return(axios.put(`${ENDPOINT}/${address}/`, data, { headers: this.HEADERS, params: parameters }))
        } else {
            return(axios.put(`${ENDPOINT}/${address}/`, data, { headers: this.HEADERS }))
        }
    }

    delete (mode, address, parameters=null) {
        let ENDPOINT = ''
        if (mode === 'CONFIG') {
            ENDPOINT = this.CONFIG_ENDPOINT;
        } else if (mode === 'AUTH') {
            ENDPOINT = this.AUTH_ENDPOINT
        } else if (mode === 'METRIC') {
            ENDPOINT = this.METRIC_ENDPOINT
        }
        if (parameters) {
            return(axios.delete(`${ENDPOINT}/${address}/`, { headers: this.HEADERS, params: parameters }))
        } else {
            return(axios.delete(`${ENDPOINT}/${address}/`, { headers: this.HEADERS }))
        }
    }

    error (error, silent=false) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log({
                data: error.response.data,
                status: error.response.status,
                headers: error.response.headers
            });
            if(! silent) {
                console.trace()
                alert(error.response.data.message)
            }
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
    }
}

export default new APIService();