import React, { Component } from "react";
import { Alert, Form, FloatingLabel } from 'react-bootstrap';
import CookieService from "../services/CookieService";
import APIService from "../services/APIService";
import { pageReady } from "../services/UtilityService";
var crypto = require('crypto');

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            valid: true,
            token: CookieService.get("token") !== undefined ? CookieService.get("token") : ''
        };
        this.handleSetInstanceID = this.handleSetInstanceID.bind(this);
        this.handleSetUsername = this.handleSetUsername.bind(this);
        this.handleSetPassword = this.handleSetPassword.bind(this);
    }

    async componentDidMount(){
        pageReady(false, false);
        if (this.state.token !== '') {
            window.location = '/';
        }
    }

	submit = async (e) => {
		e.preventDefault();
		try {
            let hash = crypto.createHash('sha256').update(this.state.password).digest('hex');
            const user = this.state.username;
            let instanceId = localStorage.getItem('instanceId');
			// Make API call here
            let params = {'password': hash}
            let currentComponent = this;
            APIService.post('AUTH', `${instanceId}/${user}/create/session`, params)
                .then(response => {
                    console.log(response)
                    CookieService.set("token", response.data.session_token)
                    localStorage.setItem('user', user);
                    this.props.history.push({
                        pathname: '/'
                    })
                    window.location.reload(false);
                }).catch(function(error) {
                    console.log(error)
                    APIService.error(error);
                    currentComponent.setState({
                        isValid: false
                    })
                });
		} catch (err) {
            console.log(err)
		}
	}

    handleSetInstanceID(event) {
        localStorage.setItem('instanceId', event.target.value)
    }

    handleSetUsername(event) {
        this.setState({username: event.target.value});
    }

    handleSetPassword(event) {
        this.setState({password: event.target.value});
    }


	render() {
        return (
                <div className="container">
                    <h1>Login</h1>
                    <br></br>
                        <Form className="form" onSubmit={this.submit}>
                            <Form.Group className="mb-3">
                            <FloatingLabel
                                    controlId="instance"
                                    label="Enter your Instance ID"
                                    className="mb-3"
                                >
                                    <Form.Control onChange={this.handleSetInstanceID} type="text" placeholder="Enter your Instance ID" />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="username"
                                    label="Enter your Username"
                                    className="mb-3"
                                >
                                    <Form.Control onChange={this.handleSetUsername} type="text" placeholder="Enter your Username" />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="password"
                                    label="Enter your Password"
                                    className="mb-3"
                                >
                                    <Form.Control onChange={this.handleSetPassword} type="password" placeholder="Enter your Password" />
                                </FloatingLabel>
                            </Form.Group>
                            <div className="form-group">
                                <input type="submit" value="Login" className="button button-primary" />
                            </div>
                            {this.state.valid ?
                                    null : (
                                    <Alert variant={'danger'}>
                                        One of the following errors has occured:
                                        <li>Invalid credentials</li>
                                    </Alert>
                                    )
                            }
                        </Form>
                        <br></br>
                </div>
        );
    }

}