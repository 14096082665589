import Cookies from 'universal-cookie'

const cookie = new Cookies();

class CookieService {
    get (key) {
        return cookie.get(key);
    }

    set(key, value) {
        cookie.set(key, `Bearer ${value}`, {path: '/', maxAge: 86400});
    }

    remove(key) {
        cookie.remove(key);
    }

    exists(key) {
        if(this.get(key) !== undefined) {
            return true;
        }
        return false;
    }
}

export default new CookieService();